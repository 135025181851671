/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'

// 创建axios实例，取代全局对象。
const instance = axios.create()
instance.interceptors.request.use(
  config => {
    // 登录流程控制中，根据本地是否存在token判断用户的登录情况
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
    // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
    const token = sessionStorage.getItem('token')
    token && (config.headers.Authorization = `Bearer ${token}`)
    // 移除空值参数
    for (const key in config.params) {
      if (
        config.params[key] === null ||
        config.params[key] === '' ||
        config.params[key] === undefined
      ) {
        delete config.params[key]
      }
    }
    for (const key in config.data) {
      if (
        config.data[key] === null ||
        config.data[key] === '' ||
        config.data[key] === undefined
      ) {
        delete config.data[key]
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

/**
 * 下载文件
 * @param content objecturl
 * @param filename 文件命名
 */
export const downloadFile = (content: any, filename: string) => {
  const a = document.createElement('a')
  a.href = content
  a.download = filename
  a.click()
}

/**
 * 获取导入模板文件
 * @param template 模板名称
 */
export const getTemplateExcel = async (template: string, name: string) => {
  const res = await instance('/api/common-all/download/template/excel', {
    params: { template },
    responseType: 'arraybuffer'
  })

  const blob = new Blob([res.data], {
    type: 'application/vnd.ms-excel'
  })
  const objectUrl = URL.createObjectURL(blob)
  downloadFile(objectUrl, name)
}

/**
 * 获取导出文件
 * @param params （exportType:导出文件字典；列表查询参数）
 * @param name 导出文件命名
 */
export const getExportExcel = async (params: any, name: string) => {
  const res = await instance({
    method: 'post',
    url: '/api/common-all/export',
    data: params,
    responseType: 'arraybuffer'
  })
  const blob = new Blob([res.data], {
    type: 'application/vnd.ms-excel'
  })
  const objectUrl = URL.createObjectURL(blob)
  downloadFile(objectUrl, name)
}
